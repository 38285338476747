import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Button from "@mui/material/Button";
import { HomologTable } from "./HomologTable";
import { useQuery } from "react-query";
import { useState } from "react";
import dayjs from "dayjs";
import api from "../../../services/api";
import { HomologConfirmacao } from "./HomologConfirmacao";

export const HomologFilters = () => {
  const [startDate, setStartDate] = useState(
    dayjs().subtract(30, "day").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [mecanico, setMecanico] = useState("Todos");
  const [solicitante, setSolicitante] = useState("Todos");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleMecanicoChange = (event) => {
    setMecanico(event.target.value);
  };

  const handleSolicitanteChange = (event) => {
    setSolicitante(event.target.value);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    if (!isLoading) {
      setOpen(false);
    }
  };
  const handleSelect = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allIds = chamados.content.flatMap((chamado) => chamado.id);
      setSelectAll(selectAll);
      setSelectedIds([...selectedIds, ...allIds]);
    }
    setSelectAll(!selectAll);
  };

  const handleSelectMobile = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  console.log("handleSelect", selectedIds);

  const { data: chamados, isLoading: isLoadingChamados } = useQuery(
    ["chamados", startDate, endDate, mecanico, solicitante, page],
    async () => {
      let resp = `/chamado/busca-finalizados?page=${page}&size=${pageSize}`;

      if (startDate && endDate) {
        resp += `&dataInicio=${dayjs(startDate).format("DD/MM/YYYY")}`;
        resp += `&dataFinal=${dayjs(endDate).format("DD/MM/YYYY")}`;
      }

      if (mecanico && mecanico !== "Todos") {
        resp += `&mecanico=${mecanico}`;
      }

      if (solicitante && solicitante !== "Todos") {
        resp += `&solicitante=${solicitante}`;
      }

      const response = await api.get(resp);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  console.log("chamados", chamados);

  const { data: mecanicos, isLoading: isLoadingMecanico } = useQuery(
    ["mecanicos"],
    async () => {
      let response = await api.get(`/mecanico/lista`);
      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  console.log("mecanico", mecanicos);

  const { data: solicitantes, isLoading: isLoadingSolicitantes } = useQuery(
    ["solicitantes"],
    async () => {
      let response = await api.get(
        `/chamado/lista-solicitantes-status?status=2`
      );
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  console.log("solicitantes", solicitantes);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setPageSize(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr 1fr",
          gap: 2,
          padding: 2,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
          <DatePicker
            label="Data Inicial"
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "40px",
                  },
                }}
              />
            )}
            dayOfWeekFormatter={(day) => {
              const daysOfWeek = [
                "Dom",
                "Seg",
                "Ter",
                "Qua",
                "Qui",
                "Sex",
                "Sab",
              ];
              return daysOfWeek[day];
            }}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
          <DatePicker
            label="Data Final"
            value={endDate}
            onChange={handleEndDateChange}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{
                  "& .MuiInputBase-root": {
                    height: "40px",
                  },
                }}
              />
            )}
            dayOfWeekFormatter={(day) => {
              const daysOfWeek = [
                "Dom",
                "Seg",
                "Ter",
                "Qua",
                "Qui",
                "Sex",
                "Sab",
              ];
              return daysOfWeek[day];
            }}
          />
        </LocalizationProvider>

        <FormControl
          fullWidth
          sx={{ "& .MuiInputBase-root": { height: "40px" } }}
        >
          <InputLabel id="demo-simple-select-solicitante-label">
            Solicitante
          </InputLabel>
          <Select
            labelId="solicitante-label"
            id="solicitante"
            label="Solicitante"
            value={solicitante}
            onChange={handleSolicitanteChange}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            {solicitantes &&
              solicitantes.map((solicitante) => (
                <MenuItem key={solicitante.id} value={solicitante.id}>
                  {solicitante.nome}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl
          fullWidth
          sx={{ "& .MuiInputBase-root": { height: "40px"} }}
        >
          <InputLabel id="mecanico-label">Mecânico</InputLabel>
          <Select
            labelId="mecanico-label"
            id="mecanico"
            label="Mecânico"
            value={mecanico}
            onChange={handleMecanicoChange}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            {mecanicos?.map((mec) => (
              <MenuItem key={mec.id} value={mec.id}>
                {mec.usuario.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box display="flex" justifyContent="flex-end" padding={2}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: isMobile ? "100%" : "auto",
            whiteSpace: "nowrap",
          }}
          onClick={handleOpen}
          disabled={selectedIds.length === 0}
        >
          Homologar
        </Button>
      </Box>

      {isLoadingChamados || isLoadingSolicitantes || isLoadingMecanico ? (
        <Skeleton variant="rectangular" width="100%" height={118} />
      ) : chamados.content.length === 0 ||
        chamados == undefined ||
        chamados.content == null ? (
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          Não há chamados para serem homologados!
        </Typography>
      ) : (
        <HomologTable
          chamados={chamados}
          isLoadingChamados={isLoadingChamados}
          open={open}
          handleClose={handleClose}
          page={page}
          pageSize={pageSize}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          handleOpen={handleOpen}
          selectedIds={selectedIds}
          handleSelect={handleSelect}
          selectAll={selectAll}
          handleSelectAll={handleSelectAll}
          setSelectedIds={setSelectedIds}
          handleSelectMobile={handleSelectMobile}
        />
      )}

      {open && (
        <HomologConfirmacao
          open={open}
          handleClose={handleClose}
          isMobile={isMobile}
          chamados={chamados}
          selectedIds={selectedIds}
          isLoadingChamados={isLoadingChamados}
          isLoadingSolicitantes={isLoadingSolicitantes}
          isLoadingMecanico={isLoadingMecanico}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};
