import {
  Box,
  Modal,
  Typography,
  Button,
  Avatar,
  TextField,
} from "@mui/material";
import api from "../../../services/api";
import { useState } from "react";
import { getControle } from "../../../services/auth";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { HomologConfigMobile } from "./HomologConfigMobile";

export const HomologConfirmacao = ({
  open,
  handleClose,
  isMobile,
  selectedIds,
  setIsLoading,
}) => {
  const [descricao, setDescricao] = useState(
    "Pedido homologado pelo administrador devido ao desligamento do solicitante."
  );
  const [isDescricaoValida, setIsDescricaoValida] = useState(true);
  const queryClient = useQueryClient();

  const usuarioControle = getControle();
  const idUsuario = localStorage.getItem("@control");

  const { data: usuario, isLoading: isLoadingUsuario } = useQuery(
    ["usuario"],
    async () => {
      let response = await api.get(`/usuarioDetalhe/${usuarioControle}`);
      return response.data.records[0];
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleDescricaoChange = (e) => {
    const value = e.target.value;
    setDescricao(value);
    setIsDescricaoValida(value.length >= 10);
  };

  const mutation = useMutation(
    async () => {
      setIsLoading(true);
      const dadosJson = {
        ids: selectedIds,
        solicitante: idUsuario,
        descricao: descricao,
        status: 3,
      };
      const response = await api.post(`chamado/homologar-chamados`, dadosJson);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("chamados");
        setIsLoading(false);
        handleClose();
      },
      onError: (error) => {
        console.log("Erro ao homologar os chamados:", error);
        setIsLoading(false);
      },
    }
  );
  const handleConfirm = () => {
    mutation.mutate();
  };

  if (isLoadingUsuario) {
    return "";
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "900px",
    bgcolor: "white",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    maxHeight: "80vh",
    overflowY: "auto",
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      sx={{ width: "100%", height: "100%", borderRadius: "none" }}
    >
      <Box sx={style}>
        {isMobile ? (
          <HomologConfigMobile
            isMobile={isMobile}
            handleClose={handleClose}
            open={open}
            usuario={usuario}
            descricao={descricao}
            setDescricao={setDescricao}
            handleConfirm={handleConfirm}
          />
        ) : (
          <Box>
            <Typography variant={"h4"}>Confirmação</Typography>
            <Box
              sx={{
                display: "grid",
                flexDirection: "row",
                gridTemplateColumns: "2fr 4fr",
                gap: 2,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Avatar
                  src={usuario.urlAvatar}
                  sx={{
                    width: 120,
                    height: 120,
                    marginLeft: 7,
                    marginTop: 2,
                    mb: 0,
                  }}
                />

                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {usuario.descricao}
                  </Typography>
                  <Typography variant="body2">{usuario.email}</Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "grid",
                  gap: 2,
                  flexDirection: "none",
                  whiteSpace: "nowrap",
                }}
              >
                <TextField
                  id="outlined-start-adornment"
                  label="."
                  value={descricao}
                  onChange={handleDescricaoChange}
                  variant="outlined"
                  fullWidth
                  multiline
                  defaultValue="Frase padrão"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "15ch",
                      whiteSpace: "normal",
                      border: `1px solid ${isDescricaoValida ? "none" : "red"}`,
                    },
                    "& .MuiInputBase-input": {
                      whiteSpace: "normal",
                      padding: 1,
                      textAlign: "center",
                    },
                  }}
                />
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography variant="subtitle1">
                    Deseja realmente homologar os chamado(s)?
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
                width: "100%",
              }}
            >
              <Button color="error" variant="contained" onClick={handleClose}>
                Sair
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleConfirm}
                disabled={!isDescricaoValida || mutation.isLoading}
              >
                {mutation.isLoading ? "Carregando..." : "Confirmar"}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
