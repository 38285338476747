import React, { Component } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import { HomologFilters } from "./Componentes/HomologFilters";

class HomologChamados extends Component {


    constructor(props) {
        super(props);
        this.state = {
          nomePagina: "Homologação de Chamados",
          nomeNivelCrumb: "Lista Registros",
          nomeNivelCrumb2: "Página Inicial",
          nomeNivelCrumb3: "Ajuda",
        };
      }

  render() {
    return (
        <div className="wrapper">
        <Header />
        <Menu />

        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <h1 className="m-0">{this.state.nomePagina} </h1>
                      </div>

                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                          <li className="breadcrumb-item active">
                            <a className="text-primary" href="/home/">
                              <i className="fa fa-home mr-1" />
                              {this.state.nomeNivelCrumb2}
                            </a>
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-body" >
                      <HomologFilters />
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default HomologChamados;
