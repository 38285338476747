import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Divider,
  IconButton,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";

export const HomologMobile = ({
  handleOpen,
  open,
  handleClose,
  chamados,
  selectedIds,
  setSelectedIds,
  page,
  pageSize,
  handlePageChange,
  handleRowsPerPageChange,
  handleSelectMobile,
}) => {
  return (
    <Box
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      {chamados.content.map((chamado) => (
        <Card
          key={chamados.id}
          sx={{
            marginBottom: 2,
            // border: selectedIds.includes(chamado.id)
            //   ? "2px solid blue"
            //   : "none",
          }}
        >
          <CardContent sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Tooltip title="Chamado">
                <Typography variant="body2">
                  <strong>58885485</strong>
                </Typography>
              </Tooltip>
              <Checkbox
                size="small"
                onClick={() => handleSelectMobile(chamado.id)}
                checked={selectedIds.includes(chamado.id)}
              />
            </Box>
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body2"> Data Abertura: </Typography>
              <Typography variant="body2">
                {" "}
                <strong>
                  {dayjs(chamado.dataAbertura).format("DD/MM/YYYY HH:mm")}
                </strong>
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body2">Solicitante: </Typography>
              <Typography variant="body2">
                <strong>{chamado.solicitante.nome}</strong>
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body2">Mecânico: </Typography>
              <Typography variant="body2">
                <strong>{chamado.mecanico.usuario.nome}</strong>
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body2">Status: </Typography>
              <Typography variant="body2">
                <strong>
                  {chamado.status === 2 ? "FINALIZADO" : chamado.status}
                </strong>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
      <TablePagination
        component="div"
        count={chamados.totalElements}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Box>
  );
};
