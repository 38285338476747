import { Avatar, Box, Button, TextField, Typography } from "@mui/material";

export const HomologConfigMobile = ({
  isMobile,
  handleClose,
  open,
  usuario,
  descricao,
  setDescricao,
  handleConfirm,
}) => {
  return (
    <Box
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Typography variant={"h4"}>Confirmação</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gridTemplateColumns: "1fr 1fr",
          gap: 1,
          mt: 1,
        }}
      >
        <Box
          sx={{
            display: "grid",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Avatar
            src={usuario.urlAvatar}
            sx={{
              width: 120,
              height: 120,
              marginLeft: 7,
              marginTop: 2,
              mb: 0,
            }}
          />

          <Box>
            <Typography
              variant="body1"
              sx={{
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {usuario.descricao}
            </Typography>
            <Typography variant="body2">{usuario.email}</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: "column",
            whiteSpace: "nowrap",
          }}
        >
          <TextField
            id="outlined-start-adornment"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            variant="outlined"
            fullWidth
            multiline
            sx={{
              "& .MuiInputBase-root": {
                height: "15ch",
                whiteSpace: "normal",
                border: "1px solid",
              },
              "& .MuiInputBase-input": {
                whiteSpace: "normal",
                padding: 1,
                textAlign: "center",
              },
            }}
          />
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%"
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                wordWrap: "break-word",
                overflowWrap: "break-word",
                maxWidth: "100%",
                flexShrink: 1,
              }}
            >
              Deseja realmente homologar os chamado(s)?
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
          width: "100%",
        }}
      >
        <Button color="error" variant="contained" onClick={handleClose}>
          Sair
        </Button>
        <Button color="primary" variant="contained" onClick={handleConfirm}>
          Confirmar
        </Button>
      </Box>
    </Box>
  );
};
