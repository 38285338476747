import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "./services/auth";

import SignIn from "./pages/SignIn";
import Home from "./pages/Home/Home";

import Maquinarios from "./pages/Maquinario/Maquinarios";
import Maquinario from "./pages/Maquinario/Maquinario";
import Atualizacoes from "./pages/Atualizacao/Atualizacoes";
import Atualizacao from "./pages/Atualizacao/Atualizacao";

import MaquinarioHistorico from "./pages/Maquinario/MaquinarioHistorico";
import Setores from "./pages/Setores/Setores";
import Setor from "./pages/Setores/Setor";

import TipoChamados from "./pages/Chamados/TipoChamado/TipoChamados";
import TipoChamado from "./pages/Chamados/TipoChamado/TipoChamado";

import Especialidades from "./pages/Especialidades/Especialidades";
import Especialidade from "./pages/Especialidades/Especialidade";
import Fabricante from "./pages/Fabricantes/Fabricante";
import Fabricantes from "./pages/Fabricantes/Fabricantes";
import NaoEncontrada from "./pages/404/404";

import Mecanicos from "./pages/Mecanico/Mecanicos";
import Mecanico from "./pages/Mecanico/Mecanico";
import GrupoMaquina from "./pages/GrupodeMaquina/GrupoMaquina";
import GrupoMaquinas from "./pages/GrupodeMaquina/GrupoMaquinas";
import ResultadoChamados from "./pages/AnaliseTempo/ResultadoChamados";
import MaquinarioSetores from "./pages/MaquinarioSetor/MaquinarioSetores";
import MaquinarioSetor from "./pages/MaquinarioSetor/MaquinarioSetor";
import Kanban from "./pages/Kanban/Kanban";
import VisualizaTodosChamados from "./components/controles/VisualizaTodosChamados";
import MaquinariosRel from "./pages/Maquinario/MaquinariosRel";
import MaquinarioSetorRel from "./pages/MaquinarioSetor/MaquinarioSetorRel";
import VisualizaTodosFinalizados from "./components/controles/VisualizaTodosFinalizados";
import Etiquetas from "./pages/Etiquetas/Etiquetas";
import Etiqueta from "./pages/Etiquetas/Etiqueta";
import SubTiposChamados from "./pages/Chamados/SubTipoDescricao/SubTiposChamados";
import SubTipoChamado from "./pages/Chamados/SubTipoDescricao/SubTipoChamado";
import KanbanRel from "./pages/Kanban/Components/KanbanRel";
import MecanicoRel from "./pages/Mecanico/MecanicoRel";
import Agendamentos from "./pages/Agendamentos/Agendamentos";
import Agendamento from "./pages/Agendamentos/Agendamento";
import Transferencia from "./pages/Transferencia/Transferencia";
import Transferencias from "./pages/Transferencia/Transferencias";
import MaquinarioHelp from "./pages/Maquinario/MaquinarioHelp";
import MaquinarioStatistic from "./pages/Maquinario/Component/MaquinarioStatistic/MaquinarioStatistic";
import Estatistica from "./pages/EstatisticaGeral/Estatistica";
import AnaliseTempo from "./pages/AnaliseTempo/AnaliseTempo";
import AtualizarVersao from "./pages/AtualizarVersao/AtualizarVersao";
import AtualizarVersoes from "./pages/AtualizarVersao/AtualizarVersoes";
import { FilterProvider } from "./components/controles/listModal/andamentos/FilterContext";
import HistoricoAnaliseMec from "./pages/AnaliseTempo/HistoricoMecanico";
import { FiltroHistMecanicos } from "./pages/AnaliseTempo/FiltroHistMecanicos";
import { PatrimonioProvider } from "./contexts/PatrimonioContext";
import { RoutesProvider } from "./contexts/RoutesContext";
import Estoque from "./pages/Estoque/Estoque";
import { EstoqueDePecas } from "./pages/Estoque/EstoquedePecas";
import { PecasContextProvider } from "./contexts/PecasContext";
import { MatprimaContextProvider } from "./contexts/MatprimaContext";
import { GetPecasContextProvider } from "./contexts/GetPecasContext";
import { VincularMatPrimaContextProvider } from "./contexts/VincularMatPrimaContext";
import { DeleteMatPrimaContextProvider } from "./contexts/DeleteMatPrimaContext";
import SolicitacaoPecas from "./pages/Estoque/SolicitacaoPecas";
import HomologChamados from "./pages/HomologChamados/HomologChamados";

/*
CONTROLE DE ROTAS
controla e protege acesso ao endereços navegaveis do sistema.
*/

/*
VERIFICA SE HÁ UMA ROTA PRIVADA
e se o usuario tem os devidos acesso a ela
*/
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={SignIn} />
      <FilterProvider>
        <Route exact path="/" component={Home} />

        <PrivateRoute path="/homeApp" component={() => <h1>App</h1>} />
        <RoutesProvider>
          <PecasContextProvider>
            <MatprimaContextProvider>
              <GetPecasContextProvider>
                <VincularMatPrimaContextProvider>
                  <DeleteMatPrimaContextProvider>
                    <PrivateRoute path="/home" component={Home} />
                    <PrivateRoute
                      path="/estatisticaGeral"
                      component={Estatistica}
                    />
                    <PrivateRoute
                      path="/analiseTempo"
                      component={AnaliseTempo}
                    />

                    <PrivateRoute
                      path="/tipochamados"
                      component={TipoChamados}
                    />
                    <PrivateRoute
                      path="/tipochamadoItem/:id"
                      component={TipoChamado}
                    />

                    <PrivateRoute
                      path="/atualizacoes"
                      component={Atualizacoes}
                    />
                    <PrivateRoute
                      path="/atualizacaoItem/:id"
                      component={Atualizacao}
                    />

                    <PrivateRoute
                      path="/homologacaoChamados"
                      component={HomologChamados}
                    />

                    <PrivateRoute path="/maquinarios" component={Maquinarios} />
                    <PrivateRoute
                      path="/maquinarioHelp/:tipo"
                      component={MaquinarioHelp}
                    />
                    <PrivateRoute
                      path="/maquinariosPrint"
                      component={MaquinariosRel}
                    />
                    <PrivateRoute
                      path="/maquinariosStatistic"
                      component={MaquinarioStatistic}
                    />
                    <PrivateRoute path="/kanbanPrint" component={KanbanRel} />
                    <PrivateRoute
                      path="/maquinarioItem/:id"
                      component={Maquinario}
                    />

                    <PrivateRoute path="/setores" component={Setores} />
                    <PrivateRoute path="/setorItem/:id" component={Setor} />

                    <PrivateRoute
                      path="/maquinarioEtiquetas"
                      component={Etiquetas}
                    />
                    <PrivateRoute
                      path="/maquinarioEtiquetasItem/:id"
                      component={Etiqueta}
                    />

                    <PrivateRoute
                      path="/especialidades"
                      component={Especialidades}
                    />
                    <PrivateRoute
                      path="/especialidadeItem/:id"
                      component={Especialidade}
                    />

                    <PrivateRoute path="/fabricantes" component={Fabricantes} />
                    <PrivateRoute
                      path="/fabricanteItem/:id"
                      component={Fabricante}
                    />

                    <PrivateRoute
                      path="/subtiposchamados"
                      component={SubTiposChamados}
                    />
                    <PrivateRoute
                      path="/subTiposChamadosItem/:id"
                      component={SubTipoChamado}
                    />

                    <PatrimonioProvider>
                      <PrivateRoute
                        path="/resultadoChamadosItem/:id"
                        component={ResultadoChamados}
                      />
                      <PrivateRoute
                        path="/maquinarioHistorico/:id"
                        component={MaquinarioHistorico}
                      />
                    </PatrimonioProvider>

                    <PrivateRoute path="/mecanicos" component={Mecanicos} />
                    <PrivateRoute
                      path="/mecanicoItem/:id"
                      component={Mecanico}
                    />
                    <PrivateRoute
                      path="/mecanicoPrint"
                      component={MecanicoRel}
                    />

                    <PrivateRoute
                      path="/historicoMecanicos/:id"
                      component={HistoricoAnaliseMec}
                    />

                    <PrivateRoute
                      path="/grupomaquinas"
                      component={GrupoMaquinas}
                    />
                    <PrivateRoute
                      path="/grupomaquinaItem/:id"
                      component={GrupoMaquina}
                    />
                    <PrivateRoute
                      path="/transferenciaItem/:id"
                      component={Transferencia}
                    />
                    <PrivateRoute
                      path="/transferencia"
                      component={Transferencias}
                    />
                    <PrivateRoute
                      path="/maquinarioSetores"
                      component={MaquinarioSetores}
                    />
                    <PrivateRoute
                      path="/maquinarioSetorItem/:id"
                      component={MaquinarioSetor}
                    />
                    <PrivateRoute
                      path="/maquinarioSetorPrint"
                      component={MaquinarioSetorRel}
                    />
                    <PrivateRoute path="/estoque" component={Estoque} />

                    <PrivateRoute
                      path="/solicitacaoPecas/:id?"
                      component={SolicitacaoPecas}
                    />

                    <PrivateRoute
                      path="/agendamentos"
                      component={Agendamentos}
                    />
                    <PrivateRoute
                      path="/agendamentoItem/:id"
                      component={Agendamento}
                    />

                    <PrivateRoute
                      path="/abertura"
                      component={AtualizarVersao}
                    />
                    <PrivateRoute
                      path="/versaoItem/:id"
                      component={AtualizarVersoes}
                    />

                    <PrivateRoute
                      path="/chamadosFilter/:st"
                      component={VisualizaTodosChamados}
                    />
                    <PrivateRoute
                      path="/chamadosFinalizados/:st"
                      component={VisualizaTodosFinalizados}
                    />

                    <PrivateRoute path="/kanban" component={Kanban} />
                  </DeleteMatPrimaContextProvider>
                </VincularMatPrimaContextProvider>
              </GetPecasContextProvider>
            </MatprimaContextProvider>
          </PecasContextProvider>
        </RoutesProvider>
      </FilterProvider>

      <Route path="*" component={NaoEncontrada} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
