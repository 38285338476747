import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { HomologMobile } from "./HomologMobile";
import { useState } from "react";
import VisualizaChamadosAdmin from "../../../components/controles/listModal/VisualizarChamadoAdm";

export const HomologTable = ({
  chamados,
  isLoadingChamados,
  open,
  handleClose,
  handleOpen,
  page,
  pageSize,
  handlePageChange,
  handleRowsPerPageChange,
  selectedIds,
  handleSelect,
  selectAll,
  handleSelectAll,
  setSelectedIds,
  handleSelectMobile,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [openDetailsCalled, setOpenDetailsCalled] = useState(false)
  const [idCalled, setIdCalled] = useState(0)

  const handleOpenChamado = (id) => {
    setIdCalled(id)
    setOpenDetailsCalled(true);
  }


  return (
    <>
      {isMobile ? (
        <HomologMobile
          handleOpen={handleOpen}
          open={open}
          handleClose={handleClose}
          chamados={chamados}
          isLoadingChamados={isLoadingChamados}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          page={page}
          pageSize={pageSize}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          handleSelectMobile={handleSelectMobile}
        />
      ) : (
        <Box>
          <TableContainer>
            <Table sx={{ border: "none", marginTop: 2 }}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" style={{ width: 20 }}>
                    <Tooltip title="Selecionar todos">
                      <Checkbox
                        size="small"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ width: 100 }}>Chamado</TableCell>
                  <TableCell style={{ width: 180 }}>Data Abertura</TableCell>
                  <TableCell style={{ width: 180 }}>Solicitante</TableCell>
                  <TableCell style={{ width: 180 }}>Mecânico</TableCell>
                  <TableCell style={{ width: 100 }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {chamados &&
                  chamados.content.map((chamado) => (
                    <TableRow key={chamado.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          size="small"
                          checked={selectedIds.includes(chamado.id)}
                          onChange={() => handleSelect(chamado.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <Link size="small" onClick={() => handleOpenChamado(chamado.id)}>
                          000{chamado.id}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {dayjs(chamado.dataAbertura).format("DD/MM/YYYY HH:mm")}
                      </TableCell>
                      <TableCell>{chamado.solicitante.nome}</TableCell>
                      <TableCell>{chamado.mecanico.usuario.nome}</TableCell>
                      <TableCell>
                        {chamado.status === 2 ? "FINALIZADO" : chamado.status}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={chamados?.totalElements || 0}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </Box>
      )}

      {openDetailsCalled && (
        <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={openDetailsCalled}
        onClose={!openDetailsCalled}
      >

        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <VisualizaChamadosAdmin selecionar={idCalled} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDetailsCalled(!openDetailsCalled)}>Fechar</Button>
        </DialogActions>
      </Dialog>
       
      )}
    </>
  );
};